import React from 'react';

import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Grid, Grow, Paper } from '@mui/material';
import Http from '@/services/http';
import { truncateString } from '@/helpers/truncate-string';
import Loading from '@/components/loading';

import { IdentifiedWeaknessCategory } from '.';

interface ListIdentifiedWeaknessesByCategoryProps {
  category: IdentifiedWeaknessCategory;
}

type ListIdentifiedWeaknessesByCategoryState = {
  name: string;
  code: string;
  proportion: number;
};

const colorPalette = [
  '#FF6EFF', // Neon Purple
  '#0055FF', // Dark Blue
  '#0000FF', // Blue
  '#00008B', // Dark Blue
  '#000080', // Navy Blue
  '#00004B', // Dark Navy Blue
  '#000033', // Midnight Blue
  '#000022', // Very Dark Blue
  '#000011', // Ultra Dark Blue
  '#000000', // Black
  '#FFA500', // Neon Orange
  '#FFFF00', // Neon Yellow
  '#7CFC00', // Neon Chartreuse
  '#00FF00', // Neon Lime
  '#00FF7F', // Neon Spring Green
  '#00CED1', // Neon Dark Turquoise
  '#008080', // Neon Teal
  '#0080FF', // Neon Azure
  '#800080', // Neon Purple
];

const getTheNextColor = (index: number) => {
  if (index > colorPalette.length - 1) {
    return colorPalette[index - colorPalette.length - 1];
  }
  return colorPalette[index];
};

const formatTooltip = (value: string) => {
  const number = Number(value);
  if (Number.isNaN(number)) return '-';
  return `${number.toFixed(2)}%`;
};

export const ListIdentifiedWeaknessesByCategory: React.FC<
  ListIdentifiedWeaknessesByCategoryProps
> = ({ category }) => {
  const [categoryStats, setCategoryStats] = React.useState<
    ListIdentifiedWeaknessesByCategoryState[] | null
  >(null);
  const [loading, setLoading] = React.useState(false);

  const fetchIdentifiedWeaknessByCategory = React.useCallback(async () => {
    setLoading(true);
    const { data } = await Http.get(
      `dashboard/identified-category-weaknesses?category=${category?.category}`
    );
    setCategoryStats(data);
    setLoading(false);
  }, [category]);

  React.useEffect(() => {
    fetchIdentifiedWeaknessByCategory();
  }, [fetchIdentifiedWeaknessByCategory]);

  const shouldRenderChart = categoryStats && categoryStats?.length && !loading;

  return (
    <Grow in unmountOnExit>
      <Grid
        container
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(480px, auto))',
        }}
        sx={{ mt: 2, gap: 2, width: '100%', ml: 0 }}
      >
        <Loading backgroundColor="transparent" loading={loading} />
        {shouldRenderChart && (
          <Paper sx={{ p: 2, width: '100%', gridColumn: '1/-1' }}>
            <ResponsiveContainer height={400} width="100%">
              <BarChart data={categoryStats} height={250}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  angle={-45}
                  dataKey="name"
                  height={150}
                  interval={0}
                  name="name"
                  textAnchor="end"
                  tickFormatter={(value) => truncateString(value, 20)}
                />
                <YAxis dataKey="proportion" name="Proporção" />
                <Tooltip formatter={formatTooltip} />
                <Bar
                  activeBar={<Rectangle fill="gold" stroke="purple" />}
                  dataKey="proportion"
                  fill="#82ca9d"
                  name="Proporção"
                >
                  {categoryStats.map((_, index) => (
                    <Cell
                      fill={getTheNextColor(index)}
                      key={`cell-${index}`}
                      stroke={getTheNextColor(index)}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
            {!category && !loading && (
              <span>
                Nenhum resultado para mostrar com o filtro selecionado.
              </span>
            )}
          </Paper>
        )}
      </Grid>
    </Grow>
  );
};
