import React from 'react';

import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import { IRif } from '@/models/rif-model';

import RifManagmnetRowSupport from '../../components/rif-managment-row-support';
import RifManagmnetRowMaintenance from '../../components/rif-managment-row-maintenance';

interface RifConcludedTableProps {
  rifList: IRif[];
}

const isMaintenance = (rif: IRif) => rif?.service?.type === 'Maintenance';

const RifConcludedTable: React.FC<RifConcludedTableProps> = ({ rifList }) => (
  <Paper sx={{ width: '100%' }}>
    <TableContainer sx={{ overflowX: 'auto' }}>
      <Table
        stickyHeader
        sx={{ maxWidth: '100%' /* , whiteSpace: 'nowrap' */ }}
      >
        <TableBody>
          {rifList.map((rif, i) =>
            isMaintenance(rif) ? (
              <RifManagmnetRowMaintenance key={i} rif={rif} />
            ) : (
              <RifManagmnetRowSupport key={i} rif={rif} />
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>
);

export default RifConcludedTable;
