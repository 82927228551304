import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  FormLabel,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { CancelOutlined, Check, Download, Home } from '@mui/icons-material';
import { getComplexities } from '@/structs/complexities';
import Http from '@/services/http';
import IRifPhoto from '@/models/rif-photo-model';
import { IRif } from '@/models/rif-model';
import { downloadUrl } from '@/helpers/download-url';
import { useDialog } from '@/contexts/dialog';
import { useAuth } from '@/contexts/auth';
import Loading from '@/components/loading';
import Header from '@/components/layouts/header';
import InfoLabel from '@/components/Info/label';

import { RifPhoto } from '../../components/rif-photo';

const RifManagementDetail: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const snackbar = useSnackbar();

  const dialog = useDialog();

  const { user } = useAuth();

  const isAdmin = useMemo(
    () => ['ADM', 'ADM - TI'].includes(user?.role || ''),
    [user]
  );

  const [loading, setLoading] = useState(false);
  const [generatingPDF, setGeneratingPDF] = useState(false);

  const [rif, setRif] = useState<IRif | undefined>();
  const [photos, setPhotos] = useState<IRifPhoto[]>([]);

  const canCancelRif = useMemo(
    () => !['cancelled', 'done', 'sent'].includes(rif?.status || ''),
    [rif]
  );

  const fetchPhotos = useCallback(async () => {
    const { status, data } = await Http.get(`rifs/${id}/photos`);

    if (status === 200) {
      setPhotos(data as IRifPhoto[]);
    }
  }, [id]);

  const fetchRif = useCallback(async () => {
    const { status, data } = await Http.get(`rifs/${id}`);

    if (status === 200) {
      setRif(data);
    } else {
      navigate('../');
    }
  }, [navigate, id]);

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchRif(), fetchPhotos()]).finally(() => {
      setLoading(false);
    });
  }, [fetchRif, fetchPhotos]);

  const handleDownloadPDF = async () => {
    setGeneratingPDF(true);

    const { data } = await Http.get(`reports/rif/${id}`, {
      responseType: 'blob',
    });
    const file = new Blob([data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, 'report.pdf');

    setGeneratingPDF(false);
  };

  const handleSubmit = async () => {
    Http.put(`rifs/${id}`, { status: 'sent' })
      .then(({ status }) => {
        if (status === 200) {
          snackbar.enqueueSnackbar('Operação executada com sucesso!', {
            variant: 'success',
          });
          navigate('/rif/management');
        } else {
          snackbar.enqueueSnackbar(
            'Ocorreu um erro, por favor tente novamente.',
            { variant: 'error' }
          );
        }
      })
      .catch(() => {
        snackbar.enqueueSnackbar(
          'Ocorreu um erro, por favor tente novamente.',
          { variant: 'error' }
        );
      });
  };

  const handleConfer = async () => {
    dialog.open({
      title: 'Confirmação',
      message: 'Tem certeza que deseja conferir o RIF?',
      buttons: [
        {
          label: 'Não',
        },
        {
          label: 'Sim',
          options: {
            variant: 'contained',
          },
          callback: handleSubmit,
        },
      ],
    });
  };

  const handleCancel = async () => {
    dialog.open({
      title: 'Confirmação',
      message: 'Tem certeza que deseja cancelar o RIF?',
      buttons: [
        {
          label: 'Não',
        },
        {
          label: 'Sim',
          options: {
            variant: 'contained',
          },
          async callback() {
            Http.post(`rifs/${id}/cancel`)
              .then(({ status }) => {
                if (status >= 200 && status <= 299) {
                  snackbar.enqueueSnackbar('R.I.F. cancelado com sucesso!', {
                    variant: 'success',
                  });

                  navigate('/rif/management');
                } else {
                  snackbar.enqueueSnackbar(
                    'Ocorreu um erro, por favor tente novamente.',
                    { variant: 'error' }
                  );
                }
              })
              .catch(() => {
                snackbar.enqueueSnackbar(
                  'Ocorreu um erro, por favor tente novamente.',
                  { variant: 'error' }
                );
              });
          },
        },
      ],
    });
  };

  return (
    <Stack spacing={1}>
      <Header
        breadcrumbs={[
          {
            icon: Home,
          },
          {
            text: 'RIF',
          },
          {
            text: 'Gestão',
            link: '/rif/management',
          },
          {
            text: rif?.task_description ?? ``,
          },
        ]}
        title="Gestão RIF"
      />
      <Paper sx={{ overflow: 'hidden' }}>
        <Loading loading={loading} />
        <Collapse in={!loading} unmountOnExit>
          <Box sx={{ m: 3 }}>
            <Stack direction="column" spacing={3}>
              <Box>
                <FormLabel sx={{ mb: 1, display: 'block' }}>
                  <strong>Identificação</strong>
                </FormLabel>
                <Paper elevation={0} sx={{ p: 1, bgcolor: 'grey.100' }}>
                  <Grid container>
                    <Grid item md={2}>
                      <InfoLabel
                        items={[
                          {
                            title: 'Código',
                            value: rif?.code,
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <InfoLabel
                        items={[
                          {
                            title: 'Data',
                            value: rif?.start_date
                              ? format(
                                  new Date(rif.start_date),
                                  'dd/MM/yyyy HH:mm'
                                )
                              : '',
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <InfoLabel
                        items={[
                          {
                            title: 'Base',
                            value: rif?.service?.base?.name,
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <InfoLabel
                        items={[
                          {
                            title: 'Complexidade',
                            value: getComplexities(
                              String(
                                rif?.service?.complexity ?? ''
                              ).toLocaleLowerCase()
                            )?.label,
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <InfoLabel
                        items={[
                          {
                            title: 'Data de realização',
                            value: rif?.service_completion_date
                              ? format(
                                  new Date(rif?.service_completion_date),
                                  'dd/MM/yyyy HH:mm'
                                )
                              : null,
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
              <Box>
                <FormLabel sx={{ mb: 1, display: 'block' }}>
                  <strong>Observador</strong>
                </FormLabel>
                <Paper elevation={0} sx={{ p: 1, bgcolor: 'grey.100' }}>
                  <Grid container>
                    <Grid item md={4}>
                      <InfoLabel
                        items={[
                          {
                            title: 'Matrícula',
                            value: rif?.auditor?.registration,
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <InfoLabel
                        items={[
                          {
                            title: 'Nome',
                            value: rif?.auditor?.name,
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <InfoLabel
                        items={[
                          {
                            title: 'Função',
                            value: rif?.auditor?.role,
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
              <Box>
                <FormLabel sx={{ mb: 1, display: 'block' }}>
                  <strong>Processos de manutenção</strong>
                </FormLabel>
                <Stack direction="column" spacing={2}>
                  <Paper elevation={0} sx={{ p: 1, bgcolor: 'grey.100' }}>
                    <InfoLabel
                      items={[
                        {
                          title: 'Processos',
                          value: rif?.maintenance_processes.join(', '),
                        },
                      ]}
                    />
                  </Paper>
                </Stack>
              </Box>
              {rif?.service?.type === 'Maintenance' && (
                <Box>
                  <FormLabel sx={{ mb: 1, display: 'block' }}>
                    <strong>Produto observado</strong>
                  </FormLabel>
                  <Stack direction="column" spacing={2}>
                    <Paper elevation={0} sx={{ p: 1, bgcolor: 'grey.100' }}>
                      <Grid container>
                        <Grid item md={12}>
                          <InfoLabel
                            items={[
                              {
                                title: 'Aeronave',
                                value: rif?.service?.aircraft?.prefix,
                              },
                            ]}
                          />
                        </Grid>
                        <Grid item md={12}>
                          <InfoLabel
                            items={[
                              {
                                title: 'Oficina de componentes',
                                value: rif?.components_factory?.join(', '),
                              },
                            ]}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Stack>
                </Box>
              )}
              <Box>
                <FormLabel sx={{ mb: 1, display: 'block' }}>
                  <strong>Processos de apoio à manutenção</strong>
                </FormLabel>
                <Stack direction="column" spacing={2}>
                  <Paper elevation={0} sx={{ p: 1, bgcolor: 'grey.100' }}>
                    <InfoLabel
                      items={[
                        {
                          title: 'Processos',
                          value: rif?.maintenance_support_processes.join(', '),
                        },
                      ]}
                    />
                  </Paper>
                </Stack>
              </Box>
              <Box>
                <FormLabel sx={{ mb: 1, display: 'block' }}>
                  <strong>Descrição da tarefa</strong>
                </FormLabel>
                <Stack direction="column" spacing={2}>
                  <Paper elevation={0} sx={{ p: 1, bgcolor: 'grey.100' }}>
                    <InfoLabel
                      items={[
                        {
                          title: 'Descrição',
                          value: rif?.service?.inspection || 'N/A',
                        },
                      ]}
                    />
                  </Paper>
                </Stack>
              </Box>
              <Box>
                <FormLabel sx={{ mb: 1, display: 'block' }}>
                  <strong>Dados técnicos ou procedimentos internos</strong>
                </FormLabel>
                <Stack direction="column" spacing={2}>
                  <Paper elevation={0} sx={{ p: 1, bgcolor: 'grey.100' }}>
                    <Grid container>
                      <Grid item md={6}>
                        <InfoLabel
                          items={[
                            {
                              title: 'Tipo',
                              value: rif?.technical_data_type,
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <InfoLabel
                          items={[
                            {
                              title: 'Identificação',
                              value: rif?.technical_data_identifier,
                            },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Stack>
              </Box>
              <Box>
                <FormLabel sx={{ mb: 1, display: 'block' }}>
                  <strong>Registro</strong>
                </FormLabel>
                <Stack direction="column" spacing={2}>
                  <Paper elevation={0} sx={{ p: 1, bgcolor: 'grey.100' }}>
                    <Grid container>
                      <Grid item md={6}>
                        <InfoLabel
                          items={[
                            {
                              title: 'Tipo',
                              value: rif?.register_type,
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <InfoLabel
                          items={[
                            {
                              title: 'Identificação',
                              value: rif?.register_identifier,
                            },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Stack>
              </Box>
              <Box>
                <FormLabel sx={{ mb: 1, display: 'block' }}>
                  <strong>Comentários do Observador</strong>
                </FormLabel>
                <Stack direction="column" spacing={2}>
                  <Paper elevation={0} sx={{ p: 1, bgcolor: 'grey.100' }}>
                    <InfoLabel
                      items={[
                        {
                          title: 'Comentários',
                          value: rif?.observer_comments,
                        },
                      ]}
                    />
                  </Paper>
                </Stack>
              </Box>
              {rif?.has_observed_events && (
                <Box>
                  <FormLabel sx={{ mb: 1, display: 'block' }}>
                    <strong>EVENTOS OBSERVADOS</strong>
                  </FormLabel>
                  {rif?.observed_events?.map((event) => (
                    <Stack direction="column" key={event?._id} spacing={2}>
                      <Stack
                        component={Paper}
                        divider={<Divider />}
                        elevation={0}
                        sx={{ bgcolor: 'grey.100' }}
                      >
                        <Grid container sx={{ p: 1 }}>
                          <Grid item md={4}>
                            <InfoLabel
                              items={[
                                {
                                  title: 'Código',
                                  value: event?.code,
                                },
                              ]}
                            />
                          </Grid>
                          <Grid item md={4}>
                            <InfoLabel
                              items={[
                                {
                                  title: 'Evento',
                                  value: event?.name,
                                },
                              ]}
                            />
                          </Grid>
                          <Grid item md={4}>
                            <InfoLabel
                              items={[
                                {
                                  title: 'Categoria',
                                  value: event?.category,
                                },
                              ]}
                            />
                          </Grid>
                        </Grid>
                      </Stack>
                    </Stack>
                  ))}
                </Box>
              )}
              <Box>
                <FormLabel sx={{ mb: 1, display: 'block' }}>
                  <strong>PARTICIPANTES</strong>
                </FormLabel>
                <Stack direction="column" spacing={1}>
                  {rif?.presence_info?.records?.map((p, index) => (
                    <Stack
                      component={Paper}
                      divider={<Divider />}
                      elevation={0}
                      key={`${p?.user?._id}-${index}`}
                      sx={{ bgcolor: 'grey.100' }}
                    >
                      <Grid alignItems="center" container sx={{ p: 1 }}>
                        <Grid item md={4}>
                          <InfoLabel
                            items={[
                              {
                                title: 'Nome',
                                value: p?.user?.name,
                              },
                            ]}
                          />
                        </Grid>
                        <Grid item md={3}>
                          <InfoLabel
                            items={[
                              {
                                title: 'Registro',
                                value: p?.user?.registration,
                              },
                            ]}
                          />
                        </Grid>

                        <Grid item md={3}>
                          <InfoLabel
                            items={[
                              {
                                title: 'Data/Hora Confirmação',
                                value: p?.confirmation_date
                                  ? format(
                                      new Date(p?.confirmation_date),
                                      'dd/MM/yyyy HH:mm'
                                    )
                                  : null,
                              },
                            ]}
                          />
                        </Grid>
                        <Grid item md={2} sx={{ textAlign: 'right' }}>
                          <Chip
                            color={
                              p?.status === 'confirmed' ? 'success' : 'default'
                            }
                            label={
                              p?.status === 'confirmed'
                                ? 'Confirmado'
                                : 'Não confirmado'
                            }
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                  ))}
                </Stack>
              </Box>
              <Box>
                <FormLabel sx={{ mb: 1, display: 'block' }}>
                  <strong>Fotos</strong>
                </FormLabel>
                <Stack direction="column" spacing={2}>
                  <Paper elevation={0} sx={{ p: 1 }}>
                    {!photos.length && (
                      <Typography textAlign="center">
                        Não há fotos anexadas.
                      </Typography>
                    )}
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        width: '100%',
                      }}
                    >
                      {photos.map((photo, index) => (
                        <Grid
                          item
                          key={index}
                          sx={{
                            height: 400,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          xs={6}
                        >
                          <RifPhoto
                            alt={`${index + 1}º imagem do RIF.`}
                            onDownload={() => {
                              if (photo.url) downloadUrl(photo.url);
                            }}
                            url={photo.url}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Paper>
                </Stack>
              </Box>
              <Stack direction="row" justifyContent="flex-end" spacing={1}>
                {isAdmin && (
                  <Button
                    color="error"
                    disabled={!canCancelRif}
                    onClick={handleCancel}
                    startIcon={<CancelOutlined />}
                  >
                    Cancelar R.I.F.
                  </Button>
                )}
                <Button
                  disabled={generatingPDF}
                  onClick={async () => handleDownloadPDF()}
                  startIcon={<Download />}
                >
                  {generatingPDF ? 'Baixando' : 'Baixar PDF'}
                </Button>
                <Button
                  color="success"
                  disabled={rif?.status !== 'done'}
                  onClick={handleConfer}
                  startIcon={<Check />}
                  variant="contained"
                >
                  Conferir
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Collapse>
      </Paper>
    </Stack>
  );
};

export default RifManagementDetail;
