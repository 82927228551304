import { FormContainer } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { Box, Button, Stack, Typography } from '@mui/material';
import Http from '@/services/http';
import { IUser } from '@/models/user-model';
import { useItemDrawer } from '@/contexts/drawer';

import UserForm from '../components/user-form';

interface IUpdateUser {
  user: IUser;
}

const UpdateUser: React.FC<IUpdateUser> = ({ user }) => {
  const form = useForm({
    defaultValues: user,
  });
  const drawerItem = useItemDrawer();
  const { enqueueSnackbar } = useSnackbar();

  const updateUserSubmit = useCallback(
    async (values: any) => {
      const { data, status } = await Http.put(
        `/fast-tracking-users/${user?._id}`,
        values
      );

      if (status === 200) {
        enqueueSnackbar('Usuário atualizado com sucesso!', {
          variant: 'success',
        });
        drawerItem.close(data);
      } else {
        enqueueSnackbar('Ocorreu um erro ao atualizar o usuário.', {
          variant: 'error',
        });
      }
    },
    [drawerItem, user._id, enqueueSnackbar]
  );

  return (
    <Box sx={{ p: 4, flexGrow: 1 }}>
      <Stack spacing={2} sx={{ height: '100%' }}>
        <Typography
          sx={{ textTransform: 'uppercase', fontSize: 20, fontWeight: 'bold' }}
          variant="h1"
        >
          {user.name}
        </Typography>
        <FormContainer
          formContext={form}
          FormProps={{
            style: { flexGrow: 1, display: 'flex', flexDirection: 'column' },
          }}
          onSuccess={updateUserSubmit}
        >
          <Box sx={{ flexGrow: 1 }}>
            <UserForm />
          </Box>
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Box>
              <Button type="submit" variant="contained">
                Atualizar
              </Button>
              <Button onClick={() => drawerItem.close()}>Cancelar</Button>
            </Box>
          </Stack>
        </FormContainer>
      </Stack>
    </Box>
  );
};

export default UpdateUser;
